//Import variables
@import "../setup/vars";

//Experience components styling
.experience {
  display: block;

  width: 100%;
  height: auto;

  background: $black;
  color: $white;
  border-radius: $border-radius $border-radius 0 0;

  padding: 0 0 75px 0;
  margin: 160px 0 0 0;

  position: relative;

  &::before {
    display: block;
    content: "";

    width: 100%;
    height: 100px;

    background: linear-gradient(-45deg, $blue, $green);
    filter: blur(20px);
    border-radius: $border-radius $border-radius 0 0;

    position: absolute;
    z-index: 0;
    left: 0;
    top: -5px;

    pointer-events: none;
  }

  .experience_wrapper {
    display: block;

    width: 100%;
    height: auto;

    background: $black;
    border-radius: $border-radius $border-radius 0 0;
    padding: 125px 0 0 0;

    position: relative;
    z-index: 1;
  }

  .experience_section {
    display: block;

    width: 100%;
    height: auto;
    max-width: $grid;

    margin: 0 auto;
    padding: 0 40px;

    &:nth-child(even) {
      margin: 75px auto 0 auto;
    }

    .experience_introduction {
      display: block;
      width: 100%;

      margin: 0 auto 45px auto;

      .title {
        margin: 0 0 5px 0;
      }
      p {
        margin: 0;
        padding: 0;
        max-width: $tinyGrid;
      }
    }
  }

  .experience_list {
    display: block;
    width: 100%;

    .experience_item {
      display: block;

      height: auto;
      width: 100%;

      position: relative;

      padding: 5px 0 0 60px;
      margin: 35px 0 0 0;

      &::before {
        content: "";
        display: block;

        height: 30px;
        width: 30px;

        position: absolute;
        top: 4px;
        left: 0;

        background: $darkGrey;
        border-radius: 100%;
      }

      .function {
        display: block;

        padding: 0;
        margin: 0 0 5px 0;
      }

      .location {
        display: block;

        padding: 0;
        margin: 0 0 20px 0;

        color: $white;
        opacity: 0.5;
      }

      .period {
        display: inline-flex;
        opacity: 0.5;
      }

      .label {
        display: inline-flex;
        align-items: center;
        align-content: center;

        height: 35px;
        width: auto;

        padding: 0 17px;

        font-size: 14px;
        border-radius: 150px;
        font-weight: $boldWeight;

        position: relative;
        left: 30px;
        top: -3px;
      }

      &:first-child {
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: 1450px) {
  .experience .experience_list .experience_item {
    .label {
      font-size: 12px;
      height: 30px;
      padding: 0 15px;
    }
  }
}

@media screen and (max-width: 900px) {
  .experience {
    .experience_section {
      padding: 0 20px;
    }

    .experience_wrapper {
      padding: 80px 0 0 0;
    }

    .experience_list .experience_item {
      padding: 0 0 0 40px;

      &::before {
        height: 20px;
        width: 20px;
        top: 2px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .experience .experience_list .experience_item {
    .location {
      margin: 0 0 0 0;
    }
    .label {
      left: 0;
      top: 10px;
    }
    .period {
      display: block;
    }
  }
}
