//Import variables
@import "../setup/vars";

//Projects components styling
.projects {
  display: block;

  width: 100%;
  height: auto;

  position: relative;

  padding: 0 0 75px 0;
  margin: 75px 0 0 0;

  &::before {
    display: block;
    content: "";

    width: 85%;
    height: calc(80% + 5px);

    background: linear-gradient(-45deg, $blue, $pink);
    filter: blur(15px);
    border-radius: $border-radius 0 0 $border-radius;

    animation: project-glow-loop 4s infinite;

    position: absolute;
    z-index: 0;
    right: 0;
    top: calc(20% + -2.5px);

    pointer-events: none;

    @keyframes project-glow-loop {
      0% {
        filter: blur(15px);
        opacity: 0.5;
      }
      50% {
        filter: blur(35px);
        opacity: 0.85;
      }
      100% {
        filter: blur(15px);
        opacity: 0.5;
      }
    }
  }
  &::after {
    display: block;
    content: "";

    width: 85%;
    height: 80%;

    background: $black;
    border-radius: $border-radius 0 0 $border-radius;

    position: absolute;
    z-index: 1;
    right: 0;
    top: calc(20% + 2.5px);

    pointer-events: none;
  }

  .project_side {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    align-content: flex-end;

    width: 80%;
    height: auto;

    flex-shrink: 0;

    margin: 0 0 0 20%;
    padding: 50px 25px 0 25px;

    position: relative;
    z-index: 2;

    .title {
      width: 100%;
      flex-shrink: 0;
      color: $white;
      margin: 0 0 35px 0;
    }
    p {
      width: 100%;
      max-width: $tinyGrid;
      flex-shrink: 0;
      color: $white;
      margin: 0;
    }
  }

  .project_list {
    display: flex;
    justify-content: center;

    width: 100%;
    height: auto;

    margin: 0 auto;

    .case_card {
      display: flex;

      width: calc((100% / 3) - 40px);
      max-width: 500px;
      height: auto;

      flex-shrink: 0;

      margin: 20px;
      aspect-ratio: 2/3;

      position: relative;
      z-index: 3;

      &.active {
        z-index: 15;
      }

      .case_card_thumbnail_element {
        display: block;

        width: 100%;
        height: 100%;

        border-radius: $border-radius;
        overflow: hidden;

        .case_image {
          display: block;

          width: 100%;
          height: 100%;

          position: relative;
          z-index: 0;

          object-fit: cover;
          object-position: center;
        }

        .case_card_thumbnail_overlay {
          display: flex;
          flex-wrap: nowrap;

          width: 100%;
          height: 100%;

          padding: 30px;

          position: absolute;
          z-index: 3;
          top: 0;
          left: 0;

          color: $white;

          pointer-events: none;

          .title {
            width: auto;
            margin: -1px 8px 0 0;
          }
          .icon {
            font-size: 18px;
          }

          &.TL {
            align-content: flex-start;
            justify-content: flex-start;
          }
          &.TR {
            align-content: flex-start;
            justify-content: flex-end;
          }
          &.BR {
            align-content: flex-end;
            align-items: flex-end;
            justify-content: flex-end;
          }
          &.BL {
            align-content: flex-end;
            align-items: flex-end;
            justify-content: flex-start;
          }
        }
      }

      .case_card_window {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;

        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;

        height: 100vh;
        width: 100%;

        .window_modal {
          display: flex;
          flex-wrap: wrap;

          height: auto;
          width: 100%;
          max-width: 800px;

          position: relative;
          z-index: 15;

          border-radius: $border-radius;
          overflow: hidden;
          background: $white;
          @include box-shadow();

          .case_image {
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;

            height: auto;
            width: 100%;

            border-radius: 0;

            object-fit: contain;
            object-position: center;

            background: rgba(0, 0, 0, 0.2);
          }

          .case_description {
            display: block;
            padding: 45px 35px;

            position: relative;

            color: $white;

            .title {
              margin: 0 0 15px 0;
              padding: 0;
            }

            p {
              margin: 0;
              padding: 0;
            }

            .close_button {
              display: flex;
              align-items: center;
              align-content: center;
              justify-content: center;

              height: 40px;
              width: 40px;

              border-radius: 100%;

              position: absolute;
              top: -20px;
              right: 20px;

              background-color: $lightGrey;
              color: $black;

              transition: all 0.2s ease;
              cursor: pointer;

              font-size: 20px;

              &:hover {
                background: $black;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .projects {
    margin: 35px 0 0 0;

    .project_list {
      .case_card {
        width: calc((100% / 3) - 20px);
        margin: 10px;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .projects {
    .project_list {
      flex-wrap: wrap;

      .case_card {
        width: calc(100% - 20px);
        margin: 10px;
        aspect-ratio: 3/4;

        &:nth-child(1) {
          left: 10%;
        }
        &:nth-child(2) {
          left: -10%;
        }
        &:nth-child(3) {
          left: 5%;
        }
      }
    }
  }
}

@media screen and (max-width: 685px) {
  .projects {
    .project_list {
      flex-wrap: wrap;

      .case_card {
        aspect-ratio: 1/1;

        &:nth-child(1) {
          left: unset;
        }
        &:nth-child(2) {
          left: unset;
        }
        &:nth-child(3) {
          left: unset;
        }

        .case_card_window .window_modal {
          min-height: 100%;
          border-radius: 0;

          .case_image {
            object-position: center bottom;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .projects {
    display: flex;
    flex-wrap: wrap;

    margin: 20px 0 0 0;

    .project_side {
      order: 0;
      width: 100%;
      flex-shrink: 0;
      margin: 0 auto 55px auto;
    }

    .project_list {
      order: 1;
      width: 100%;
      flex-shrink: 0;
    }
  }
}
