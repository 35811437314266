//Import variables
@import "../setup/vars";

//Skills components styling
.skills {
  display: flex;

  width: 100%;
  max-width: $grid;
  height: auto;
  flex-shrink: 0;

  margin: 0 auto;
  padding: 120px 20px;

  .skill_extender {
    display: none;
    justify-content: center;
    align-items: center;
    align-content: center;

    background: rgba(255, 255, 255, 0.75);
    color: $black;

    border-radius: 150px;

    padding: 10px 17px 10px 10px;
    margin: 15px auto 0 auto;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;

      height: 30px;
      width: 30px;

      background: #8dd9ce;
      color: $white;
      border-radius: 100%;

      margin: 0 10px 0 0;
    }

    .text {
      font-family: $font;
      font-weight: $boldWeight;
      font-size: 16px;
    }
  }

  .scroller_wrapper {
    position: relative;
    z-index: 6;

    .skill_scroller {
      display: block;

      width: 450px;

      flex-grow: 0;
      flex-shrink: 0;

      position: relative;
      z-index: 2;

      .skill_cards {
        position: absolute;

        width: 100%;
        height: auto;

        list-style: none;
        padding: 0;
        margin: 0;

        .skill_card {
          display: flex;
          align-items: center;
          align-content: center;

          height: auto;
          width: 100%;

          position: relative;

          background: $white;

          border-radius: $border-radius;
          @include box-shadow();
          overflow: hidden;

          padding: 30px 35px;

          transition: all 0.3s ease;
          animation: intro-skill-cards ease-out 0.5s;
          animation-fill-mode: forwards;

          /* Starting values for animation */
          top: 60px;
          opacity: 0;
          filter: blur(2.5px);

          .icon {
            display: block;

            margin: 0 22.5px 0 0;

            color: inherit;
            font-size: 40px;
          }

          .name {
            font-weight: $boldWeight;
            font-size: 23px;
            color: $black;
          }
        }
      }

      &.scroll_object {
        width: 100%;
        height: 100%;

        overflow-y: scroll;
        opacity: 0;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;

        &::-webkit-scrollbar {
          width: 0;
        }

        .skill_cards {
          position: relative;

          .skill_card {
            background: hotpink !important;
          }
        }

        .scroll_stretcher {
          display: block;
          height: 305px;
          width: 100%;
        }
      }
    }
  }

  .skill_side {
    display: block;

    width: calc(100% - 450px);
    height: auto;

    flex-shrink: 0;
    flex-grow: 0;

    padding: 20px 20px 20px 50px;

    p {
      font-weight: $regularWeight;
      color: $white;
      max-width: $tinyGrid;
    }
  }

  .skill_switcher {
    display: flex;
    align-items: center;
    align-content: center;

    height: auto;
    width: 100%;

    cursor: pointer;

    .switch_name {
      color: $white;
      font-weight: $blackWeight;

      opacity: 1;
      transition: opacity 0.25s ease;
    }

    .toggle {
      display: block;

      height: 30px;
      width: 60px;

      background: rgba(255, 255, 255, 0.75);
      border-radius: 150px;

      position: relative;
      z-index: 0;

      margin: 0 35px;

      &::before {
        content: "";
        display: block;

        height: 22px;
        width: 22px;

        background: #8dd9ce;
        border-radius: 100%;

        position: absolute;
        top: 4px;

        transition: all 0.25s ease-out;
      }
    }

    //Switcher directions
    &.left {
      .switch_name {
        &.r {
          opacity: 0.45;

          &:hover {
            opacity: 1;
          }
        }
      }

      .toggle {
        &::before {
          left: 4px;
        }

        &:hover {
          &::before {
            transform: scale(0.85);
          }
        }
      }
    }

    &.right {
      .switch_name {
        &.l {
          opacity: 0.45;

          &:hover {
            opacity: 1;
          }
        }
      }

      .toggle {
        &::before {
          left: calc(100% - 22px - 4px);
        }

        &:hover {
          &::before {
            transform: scale(0.85);
          }
        }
      }
    }
  }
}

@keyframes intro-skill-cards {
  0% {
    top: 60px;
    opacity: 0;
    filter: blur(2.5px);
  }
  100% {
    top: 0;
    opacity: 1;
    filter: blur(0);
  }
}

@media screen and (max-width: 1200px) and (min-width: 951px) {
  .skills .skill_switcher {
    flex-wrap: wrap;
    position: relative;
    padding: 0 0 0 50px;

    .switch_name {
      width: 100%;
      flex-shrink: 0;
    }

    .toggle {
      position: absolute;
      left: -50px;
      transform: rotate(90deg);
    }
  }
}

@media screen and (max-width: 950px) {
  .skills {
    flex-wrap: wrap;
    padding: 45px 20px 15px 20px;

    .skill_side {
      width: 100%;
      flex-shrink: 0;
      order: 0;
      text-align: center;
      padding: 0 0 35px 0;
    }

    .skill_extender {
      display: flex;
    }

    .skill_switcher {
      margin: 0 auto;
      justify-content: center;
    }

    .scroller_wrapper {
      margin: 0 auto;
      order: 1;
      width: 100%;

      .skill_scroller {
        .skill_cards {
          position: relative;

          .skill_card {
            border-radius: $border-radius / 1.3;
            .icon {
              font-size: 30px;
            }
            .name {
              font-size: 18px;
            }
          }
        }

        &.stack_open {
          .skill_cards {
            .skill_card {
              margin-top: 0 !important;
              margin-bottom: 10px !important;
              transform: scale(1) !important;
            }
          }
        }

        &.scroll_object {
          display: none;
          pointer-events: none;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .skills {
    padding: 45px 0 25px 0;

    .skill_switcher {
      flex-wrap: wrap;
      padding: 0;

      .switch_name {
        width: 100%;
        flex-shrink: 0;
        order: 1;
      }

      .toggle {
        order: 0;
        margin-bottom: 15px;
      }
    }
  }
}

@media screen and (max-width: 525px) {
  .skills .scroller_wrapper .skill_scroller {
    width: 100%;
  }
}
